import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Button, Header, Loader } from 'components';
import { SubscriptionDetailsDto } from 'models/subscriptions';
import subscriptionApi from 'api/subscription';
import { Status } from 'models/member';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectProfile } from 'redux/selectors/profile';
import { RadioItemType } from 'components/RadioItemsList';
import { profileActions } from 'redux/reducers/profile';
import { Reccuring } from 'helpers/subscriptions';
import {
  Pricing,
  SubscriptionDetailInfo,
  SubscriptionsPlanList,
  MobileSubscriptionsDetails,
} from './components/index';

const SubscriptionPlan = (): JSX.Element => {
  const [availablePlans, setAvailablePlans] = useState<SubscriptionDetailsDto[]>([]);
  const [activePlan, setActivePlan] = useState<RadioItemType>();
  const [isLoading, setIsLoading] = useState(false);
  const [isAnnualSelected, setIsAnnualSelected] = useState(true);
  const [isMobilePlanDetailsOpen, setMobilePlanDetailsOpen] = useState(false);

  const user = useAppSelector(selectProfile);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isOnlyAnnualAvailable = false;

  useEffect(() => {
    if (isLoading || availablePlans?.length > 0) return;
    getAvailablePlans();
  }, [isLoading, availablePlans?.length]);

  useEffect(() => {
    if (user?.status !== Status.APPROVED) {
      navigate('/');
    }
  }, [user, navigate]);

  useEffect(() => {
    if (isOnlyAnnualAvailable && !isAnnualSelected) {
      setIsAnnualSelected(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnlyAnnualAvailable]);

  const handleSubmit = async () => {
    if (!activePlan) return;

    const currentPlan = availablePlans.find((plan) => plan.id === activePlan.value);
    const currentPrice = currentPlan?.prices.find((price) =>
      isAnnualSelected ? price.reccuring === Reccuring.YEAR : price.reccuring === Reccuring.QUERTER
    );

    if (!currentPrice) return;

    const { url } = await subscriptionApi.createSubscription(currentPrice.id);
    if (!url) return;
    await dispatch(profileActions.getProfile());
    window.location.href = url;
  };

  const getAvailablePlans = async () => {
    try {
      setIsLoading(true);
      const { subscriptions } = await subscriptionApi.getSubscriptions();

      if (subscriptions?.length) {
        setAvailablePlans(subscriptions);
        const defaultSubscription = subscriptions.find((x) => x.isDefault) || subscriptions[0];
        setActivePlan({ value: defaultSubscription.id, label: defaultSubscription.name });
      }
    } catch (error) {
      console.log("Can't get available subscription plans", error);
    } finally {
      setIsLoading(false);
    }
  };

  const subscriptionOptions = useMemo(
    () =>
      availablePlans.length
        ? availablePlans.map((plan) => ({
            label: plan.name,
            value: plan.id,
            description: plan.description,
          }))
        : [],
    [availablePlans]
  );

  const selectedPlanInformation = useMemo(
    () =>
      availablePlans.length
        ? availablePlans.find((plan) => plan.id === activePlan?.value)
        : undefined,
    [activePlan?.value, availablePlans]
  );

  const isPassportFeeActive = useMemo(() => {
    // if (user?.freePassportFeeExpirationDate) {
    //   const feeExpirationDate = moment(user.freePassportFeeExpirationDate);
    //   const currentDate = moment();
    //   return currentDate.isAfter(feeExpirationDate);
    // }
    //return false;
    return true;
  }, [user]);

  const handleOpenMobileDetails = (isOpen: boolean) => {
    setMobilePlanDetailsOpen(isOpen);
  };

  return (
    <>
      {!isMobilePlanDetailsOpen ? (
        <div className="payments-page">
          {!isLoading ? (
            <>
              <div className="payments-page-wrapper flex-center">
                <Header onBack={() => navigate(-1)} withBackArrow={false} />
                <div className="payments-page-wrapper__info">
                  <Pricing
                    isOnlyAnnualAvailable={isOnlyAnnualAvailable}
                    passportFeeActive={isPassportFeeActive}
                    isAnnualSelected={isAnnualSelected}
                    onPeriodChange={() => setIsAnnualSelected(!isAnnualSelected)}
                    selectedPlanPricing={selectedPlanInformation?.prices}
                  />
                  <SubscriptionsPlanList
                    options={subscriptionOptions}
                    onSelectItem={(item: RadioItemType) => setActivePlan(item)}
                    selected={activePlan}
                  />
                  <div className="payments-page-wrapper__desktop-block">
                    <SubscriptionDetailInfo selectedSubscriptionInfo={selectedPlanInformation} />
                  </div>
                </div>
                <Button text="Continue" type="button" onClick={handleSubmit} />
              </div>
              <div
                className="payments-page-wrapper__compare-plans learn-more-button"
                onClick={() => handleOpenMobileDetails(true)}
              >
                Compare Plans
              </div>
            </>
          ) : (
            <div className="payments-loader-wrapper">
              <Loader />
            </div>
          )}
        </div>
      ) : (
        <div className="payments-page">
          <MobileSubscriptionsDetails
            options={subscriptionOptions}
            onSelectItem={(item: RadioItemType) => setActivePlan(item)}
            handleOpenMobileDetails={handleOpenMobileDetails}
            selectedSubscriptionInfo={selectedPlanInformation}
            selected={activePlan}
          />
        </div>
      )}
    </>
  );
};

export default SubscriptionPlan;
