import React, { FC } from 'react';
import { ThreeDots } from 'react-loader-spinner';

type Props = {
  color?: string;
  height?: number;
  width?: number;
};

const Loader: FC<Props> = ({ color = '#000', height = 150, width = 250 }) => {
  return (
    <div className="loader-wrapper">
      <ThreeDots color={color} height={width} width={height} />
    </div>
  );
};

export default Loader;
