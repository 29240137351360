import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Button, Header, Input } from 'components';
import { FormikHelpers, useFormik } from 'formik';
import { Status } from 'models/member';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { authActions } from 'redux/reducers/auth';
import { selectProfile } from 'redux/selectors/profile';
import * as yup from 'yup';
import ReactGA from 'react-ga4';

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters'),
  passwordConfirm: yup
    .string()
    .required('Confirm you password')
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .min(8, 'Password must be at least 8 characters'),
});

export interface SetPasswordValuesModel {
  password: string;
  passwordConfirm: string;
}

const SetPassword = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(selectProfile);

  useEffect(() => {
    if (
      (user?.status !== Status.ACTIVE && user?.status !== Status.PAST_DUE) ||
      user?.isPasswordSet
    ) {
      navigate('/');
    } else {
      ReactGA.event('subscription_purchase', { userId: user._id });
    }
  }, [user, navigate]);

  const submitHandler = useCallback(
    (
      values: SetPasswordValuesModel,
      { resetForm }: FormikHelpers<SetPasswordValuesModel>
    ): void => {
      dispatch(authActions.setPassword({ password: values.password }));
      resetForm({});
    },
    [dispatch]
  );

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirm: '',
    },
    validationSchema: validationSchema,
    onSubmit: submitHandler,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <>
      <Header withBackArrow={false} hasLogo={false} title="The Final Step" />
      <div className="set-password-page">
        <div className="container">
          <span className="beaufortPro-normal set-password-subtitle">
            Your payment was successful! All that's left is for you to create a password for your
            Aethos account.
          </span>
          <form onSubmit={formik.handleSubmit} className="form-container">
            <Input
              id={'password'}
              name={'password'}
              type={'password'}
              label={'Create New Password'}
              value={formik.values.password}
              onChange={formik.handleChange}
              isError={formik.touched.password && Boolean(formik.errors.password)}
              errorText={formik.errors.password}
            />
            <Input
              id={'passwordConfirm'}
              name={'passwordConfirm'}
              type={'password'}
              label={'Confirm New Password'}
              value={formik.values.passwordConfirm}
              onChange={formik.handleChange}
              isError={formik.touched.passwordConfirm && Boolean(formik.errors.passwordConfirm)}
              errorText={formik.errors.passwordConfirm}
            />
            <Button type={'submit'} text={'Confirm'} />
          </form>
        </div>
      </div>
    </>
  );
};

export default SetPassword;
