import { useAppSelector } from 'app/hooks';
import { Header } from 'components';
import { Status } from 'models/member';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { selectProfile } from 'redux/selectors/profile';

const WaitingReview = (): JSX.Element => {
  const navigate = useNavigate();
  const user = useAppSelector(selectProfile);

  useEffect(() => {
    if (user?.status !== Status.AWAITING_REVIEW) {
      navigate('/');
    }
  }, [user, navigate]);

  return (
    <>
      <div className="waiting-review-container">
        <Header withBackArrow={false}></Header>
        <div className="container waiting-review-wrapper">
          <span className="waiting-review-header">Magic Awaits</span>
          <div className="waiting-review-description-container">
            <span className="waiting-review-description">
              Thank you for applying — we will be in touch soon! If you need anything else in the
              meantime, email us at{' '}
              <a className="waiting-review-link" href="mailto:hello@yayem.co">
                hello@yayem.co
              </a>
            </span>
          </div>
          {/* <div className="link-container">
            <a
              href="https://yayem.co/"
              target="_blank"
              rel="noreferrer"
              className="learn-more-button"
            >
              Explore Aethos
            </a>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default WaitingReview;
