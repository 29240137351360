import { useJsApiLoader } from '@react-google-maps/api';
import { useState } from 'react';
import ReactGA from 'react-ga4';
import MainRouter from 'routes';
import HistoryRouter from 'routes/HistoryRouter';
import history from 'utils/route-history';
import ReactPixel from 'react-facebook-pixel';

// ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_PROJECT_ID as string);

// const options = {
//   autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
//   debug: false, // enable logs
// };
// ReactPixel.init('977702077063077', undefined, options);
// ReactPixel.grantConsent();

const App = () => {
  // const [libraries] = useState(['places']);

  // useJsApiLoader({
  //   id: 'google-map-script',
  //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
  //   /* @ts-ignore */
  //   libraries,
  // });

  return (
    <HistoryRouter history={history}>
      <MainRouter />
    </HistoryRouter>
  );
};

export default App;
