export class MemberDto {
  _id: string;
  status: Status;
  userType: UserType;
  email: string;
  brazeId: string;
  airtableId: string;
  customerId: string;
  passwordHash: string | null;
  createdAt: Date;
  updatedAt: Date | null;
  referralCode: string;
  isPasswordSet: boolean;
  onboardingSettings: Partial<OnboardingSettings>;
  profile: Partial<MemberProfile>;
  freePassportFeeExpirationDate: string;
  subscription: UserSubscription;
}

export type UserSubscription = {
  productId: string;
  productName: string;
};

export type OnboardingSettings = {
  referredBy: Pick<MemberDto, 'referralCode'>;
  expediteCode: string;
  promoCode: string;
};

export enum Status {
  NEW = 'new',
  AWAITING_REVIEW = 'awaiting_review',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PAST_DUE = 'past_due',
  PAUSED = 'paused',
  WAITLIST = 'waitlist',
}

export type MemberProfile = {
  firstName?: string;
  lastName?: string;
  birthday?: string;
  gender?: Gender;
  countryOfResidence?: string;
  secondaryCities?: string[];
  industry?: string;
  linkedin?: string;
  instagram?: string;
  travelStory?: string;
  perfectDay?: string;
  excitesyou?: string[];
};

export enum Gender {
  HE = 'HE/HIM',
  SHE = 'SHE/HER',
  THEY = 'THEY/THEM',
  NO = 'I PREFER NOT TO SAY',
}

export enum UserType {
  MEMBER = 'member',
  PARTNER = 'partner',
  ADMIN = 'admin',
  ACCOUNT = 'account',
  CHECK_IN = 'check-in',
}

export enum HowYouKnowAboutUs {
  LATITUDE_59 = 'latitude_59',
  REFERRAL = 'referral',
  YAYEM_EXPERIENCE = 'yayem_experience',
  AETHOS_EXPERIENCE = 'aethos_experience',
  INSTAGRAM = 'instagram',
  OTHER = 'other',
}

export type HowYouKnowAboutUsType = {
  userId?: string;
  howYouKnowAboutUs?: HowYouKnowAboutUs;
  referralCode?: string;
  otherText?: string;
};

export type reApplyMemberType = {
  reApplyMessage: string;
};
