import { useAppDispatch } from 'app/hooks';
import { Button, Header, Input, Loader } from 'components';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { hotspotActions } from 'redux/reducers/hotspot';
import { selectHotspotLoading, selectHotspotPublicData } from 'redux/selectors/hotspot';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  email: yup.string().email('Enter a valid email').required('Required'),
});

type QueryParams = {
  id: string;
};

export interface HotspotRequestViewValuesModel {
  email: string;
}

const GuideViewSignIn = (): JSX.Element => {
  const { id } = useParams<QueryParams>();
  const [requested, setRequested] = useState(false);
  const dispatch = useAppDispatch();

  const hotspotPublicData = useSelector(selectHotspotPublicData);
  const isLoading = useSelector(selectHotspotLoading);

  useEffect(() => {
    if (id) {
      dispatch(hotspotActions.getHotspot(id));
    }
  }, [id]);

  useEffect(() => {
    if (hotspotPublicData) {
      document.title = hotspotPublicData.name;
      // set description
      document
        .querySelector('meta[name="description"]')
        ?.setAttribute('content', 'Check the guide');

      // Set or create a meta tag for the OG image
      let metaImage = document.querySelector('meta[property="og:image"]');
      if (!metaImage) {
        metaImage = document.createElement('meta');
        metaImage.setAttribute('property', 'og:image');
        document.getElementsByTagName('head')[0].appendChild(metaImage);
      }
      metaImage.setAttribute('content', hotspotPublicData.imageUrl);
    }
  }, [hotspotPublicData]);

  const onSubmitted = useRef(false);

  const submitHandler = useCallback(
    (values: HotspotRequestViewValuesModel): void => {
      if (!onSubmitted.current) {
        onSubmitted.current = true;
        dispatch(
          hotspotActions.requestGuideView({
            _id: id as string,
            email: values.email,
          })
        );
        setRequested(true);
        onSubmitted.current = false;
      }
    },
    [dispatch]
  );

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: submitHandler,
    validateOnBlur: true,
  });

  return (
    <div>
      <Header withBackArrow={false} isBlackLogo={true}></Header>
      <form className="page" onSubmit={formik.handleSubmit}>
        <div className="guide-view-login-page">
          <div>
            <p className="guide-view-login-page__header-text">
              EXCLUSIVE CONTENT FOR YOUR EYES ONLY 👀
            </p>
          </div>
          <div className="guide-view-login-page__main-container">
            <div className="guide-view-banner-container">
              <img
                className="guide-view-banner-image"
                src={hotspotPublicData?.imageUrl}
                alt="banner"
              />
              <span className="guide-view-banner-text">{hotspotPublicData?.name}</span>
              <span className="guide-view-banner-author">by {hotspotPublicData?.author}</span>
            </div>
            <div className="guide-view-input-container">
              {isLoading ? (
                <Loader color={'#000'} />
              ) : requested ? (
                <div className="guide-view-requested-text">Check your email, babe!</div>
              ) : (
                <div className="guide-view-input">
                  <Input
                    id={'email'}
                    name={'email'}
                    type={'text'}
                    label={'Email'}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    isError={formik.touched.email && Boolean(formik.errors.email)}
                    errorText={formik.errors.email}
                    autoFocus
                  />
                  <Button
                    type={'submit'}
                    text={'UNLOCK'}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="guide-view-login-page__footer-text">
            <p>
            Aethos members always know the spots, thanks to exclusive guides covering the world’s
              best cities.
            </p>
            <p>
              <a href="https://yayem.co" className="link-text">
                Is this you? 😉 Learn more here.
              </a>
            </p>
          </div>
        </div>
      </form>
    </div>
  );
};

export default GuideViewSignIn;
