import { useAppDispatch } from 'app/hooks';
import { logoIcon } from 'assets/images';
import { Button, Input, InputNew } from 'components';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useRef } from 'react';
import { authActions } from 'redux/reducers/auth';
import { EXPIDATE_CODE, PROMO_CODE, REFERRAL_CODE, TAG } from 'utils/storageKeys';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  email: yup.string().email('Enter a valid email').required('Required'),
});

export interface LoginValuesModel {
  email: string;
}

const Login = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const onSubmitted = useRef(false);

  const submitHandler = useCallback(
    (values: LoginValuesModel): void => {
      if (!onSubmitted.current) {
        onSubmitted.current = true;
        dispatch(
          authActions.tryLoginMember({
            email: values.email,
            referralCode: sessionStorage.getItem(REFERRAL_CODE) || undefined,
            expediteCode: sessionStorage.getItem(EXPIDATE_CODE) || undefined,
            promoCode: sessionStorage.getItem(PROMO_CODE) || undefined,
            tag: sessionStorage.getItem(TAG) || undefined,
          })
        );
      }
    },
    [dispatch]
  );

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: submitHandler,
    validateOnBlur: true,
  });
  return (
    <div>
      {/* <div className="running-line">
        <span className="running-line-text">
          {` 🦪🦪 THE WORLD IS YOUR OYSTER 🦪🦪 SHUCK IT WITH YAYEM 🦪🦪 THE WORLD IS YOUR OYSTER 🦪🦪 SHUCK IT WITH YAYEM `}
          {` 🦪🦪 THE WORLD IS YOUR OYSTER 🦪🦪 SHUCK IT WITH YAYEM 🦪🦪 THE WORLD IS YOUR OYSTER 🦪🦪 SHUCK IT WITH YAYEM `}
          {` 🦪🦪 THE WORLD IS YOUR OYSTER 🦪🦪 SHUCK IT WITH YAYEM 🦪🦪 THE WORLD IS YOUR OYSTER 🦪🦪 SHUCK IT WITH YAYEM `}
          {` 🦪🦪 THE WORLD IS YOUR OYSTER 🦪🦪 SHUCK IT WITH YAYEM 🦪🦪 THE WORLD IS YOUR OYSTER 🦪🦪 SHUCK IT WITH YAYEM `}
          {` 🦪🦪 THE WORLD IS YOUR OYSTER 🦪🦪 SHUCK IT WITH YAYEM 🦪🦪 THE WORLD IS YOUR OYSTER 🦪🦪 SHUCK IT WITH YAYEM `}
          {` 🦪🦪 THE WORLD IS YOUR OYSTER 🦪🦪 SHUCK IT WITH YAYEM 🦪🦪 THE WORLD IS YOUR OYSTER 🦪🦪 SHUCK IT WITH YAYEM `}
          {` 🦪🦪 THE WORLD IS YOUR OYSTER 🦪🦪 SHUCK IT WITH YAYEM 🦪🦪 THE WORLD IS YOUR OYSTER 🦪🦪 SHUCK IT WITH YAYEM `}
          {` 🦪🦪 THE WORLD IS YOUR OYSTER 🦪🦪 SHUCK IT WITH YAYEM 🦪🦪 THE WORLD IS YOUR OYSTER 🦪🦪 SHUCK IT WITH YAYEM `}
          {` 🦪🦪 THE WORLD IS YOUR OYSTER 🦪🦪 SHUCK IT WITH YAYEM 🦪🦪 THE WORLD IS YOUR OYSTER 🦪🦪 SHUCK IT WITH YAYEM `}
          {` 🦪🦪 THE WORLD IS YOUR OYSTER 🦪🦪 SHUCK IT WITH YAYEM 🦪🦪 THE WORLD IS YOUR OYSTER 🦪🦪 SHUCK IT WITH YAYEM `}
          {` 🦪🦪 THE WORLD IS YOUR OYSTER 🦪🦪 SHUCK IT WITH YAYEM 🦪🦪 THE WORLD IS YOUR OYSTER 🦪🦪 SHUCK IT WITH YAYEM `}
          {` 🦪🦪 THE WORLD IS YOUR OYSTER 🦪🦪 SHUCK IT WITH YAYEM 🦪🦪 THE WORLD IS YOUR OYSTER 🦪🦪 SHUCK IT WITH YAYEM `}
        </span>
      </div> */}

      <div className={`header`} style={{ marginTop: 72 }}>
        <img src={logoIcon} width={114} alt="Logo"></img>
      </div>
      <form className="page" onSubmit={formik.handleSubmit}>
        <div className="login-container container">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="login-front-secondary-text">
              The club for curious travelers with a taste for the extraordinary
            </span>
          </div>
          <InputNew
            id={'email'}
            name={'email'}
            type={'text'}
            label={'EMAIL'}
            value={formik.values.email}
            onChange={formik.handleChange}
            isError={formik.touched.email && Boolean(formik.errors.email)}
            errorText={formik.errors.email}
           
            autoFocus
          />
          <Button
            type={'submit'}
            text={`BEGIN`}
            containerStyle={{ width: '100%', maxWidth: 400 }}
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="login-front-additional-text">
              Already accepted? You’re in the right place.
            </span>
            <span className="login-front-additional-text">
              Enter your email to confirm membership.
            </span>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;
