import { useAppDispatch } from 'app/hooks';
import { logoIcon } from 'assets/images';
import { Button, Loader } from 'components';
import React, { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { hotspotActions } from 'redux/reducers/hotspot';
import { selectHotspotLoading, selectHotspotPrivateData } from 'redux/selectors/hotspot';
import useWindowDimensions from 'utils/useWindowDimensions';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

type QueryParams = {
  id: string;
};

const GuideView = (): JSX.Element => {
  const { id } = useParams<QueryParams>();
  const onVeifyRequestSentRef = useRef(false);
  const { height, width } = useWindowDimensions();
  const [searchParams, setSearchParams] = useSearchParams();
  const otp = searchParams.get('otp');
  const uuid = searchParams.get('uuid');

  const [guidePdfPagesNum, setGuidePdfPagesNum] = useState(0);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const hotspotPrivateData = useSelector(selectHotspotPrivateData);
  const isLoading = useSelector(selectHotspotLoading);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (!onVeifyRequestSentRef.current) {
      if (id && otp && uuid) {
        dispatch(
          hotspotActions.verifyAndGetGuideView({
            id: id,
            otp: otp,
            userId: uuid,
          })
        );
      } else {
        if (!hotspotPrivateData) {
          navigate(`/guide-view-request/${id}`);
        }
      }
      onVeifyRequestSentRef.current = true;
    }
  }, [isLoading]);

  const Footer = () => {
    return (
      <div className="guide-view-footer">
        <div className="guide-view-footer__container">
          <img
            style={{ paddingLeft: 42, display: width < 1024 ? 'none' : 'auto' }}
            src={logoIcon}
            width={114}
            alt="Logo"
          ></img>
          <div className="guide-view-footer-text" style={{ width: width < 1024 ? '60%' : '100%' }}>
            {`Want more recs by ${hotspotPrivateData?.author}? join us -->`}
          </div>
          <div style={{ paddingRight: width < 1024 ? 16 : 42, width: 150 }}>
            <Button
              text="JOIN US"
              type="button"
              onClick={() => {
                window.location.href = 'https://join.yayem.co';
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="guide-view-page">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Document
              file={hotspotPrivateData?.pdfUrl}
              onLoadSuccess={(d) => setGuidePdfPagesNum(d.numPages)}
            >
              {Array.from({ length: guidePdfPagesNum }, (_, index) => (
                <Page
                  className={'guide-view-page-item'}
                  key={index + 1}
                  pageNumber={index + 1}
                  width={width < 1024 ? (width - 48) : undefined}
                  scale={width < 1024 ? 1 : 1.2}
                />
              ))}
            </Document>
            <Footer />
          </>
        )}
    </div>
  );
};

export default GuideView;
