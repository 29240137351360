import React from 'react';

interface Props {
  title?: string;
  subtitle?: string;
  onClick: () => void;
  className?: string;
}

const defaultTitle = 'Unlock your membership';
const defaultSubTitle = 'Download the Aethos app now';

const DownloadAppButton: React.FC<Props> = ({ title, subtitle, onClick, className }) => {
  const btnClassName = className ?? '';
  return (
    <div className={`download-btn-container ${btnClassName}`}>
      <button className="download-btn" type={'button'} onClick={onClick}>
        <div className="download-btn-title-wrap">
            <span className="dia-bold download-btn-title">{title || defaultTitle}</span>
            <span className="dia-normal download-btn-subtitle">{subtitle || defaultSubTitle}</span>
        </div>
      </button>
    </div>
  );
};

export default DownloadAppButton;
